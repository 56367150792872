<template>
  <div class="info-table">
    <div style="display: flex">
      <span class="inner-title">动静脉信息{{ isReadonly ? '' : '录入' }}</span>
    </div>
    <div style="margin: 10px 0 0 10px">
      <div v-show="!isReadonly">
        <el-button
          size="mini"
          type="primary"
          class="commonBtn"
          @click="nerveCcfInsert"
          :disabled="signed_status"
        >
          新增
        </el-button>
        <el-button
          size="mini"
          type="warning"
          class="commonBtn"
          @click="nerveCcfModify"
          :disabled="signed_status"
        >
          修改
        </el-button>
        <el-button
          size="mini"
          type="danger"
          class="commonBtn"
          @click="nerveCcfDelete"
          :disabled="signed_status"
        >
          删除
        </el-button>
      </div>

      <el-table
        class="inner-table"
        highlight-current-row
        :data="nerveCcfTableData"
        :header-cell-style="{ padding: 0 }"
        height="200px"
        style="width: 99%"
        :key="tableKey"
        @row-click="_rowClick"
        @row-dblclick="_doubleModify"
        border
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="lesionSite" label="病变部位"></el-table-column>
        <el-table-column prop="complication" label="合并症"></el-table-column>
        <el-table-column prop="fistulaLocation" label="瘘口位置"></el-table-column>
        <el-table-column prop="feedingArteryName" label="供血动脉名称"></el-table-column>
        <el-table-column prop="drainageVeinName" label="引流静脉名称"></el-table-column>
        <el-table-column prop="drainageVeinType" label="引流静脉类型"></el-table-column>
        <el-table-column
          prop="hasInterventionalTherapy"
          label="既往有无介入治疗史"
        ></el-table-column>
        <el-table-column
          prop="hasCoilOnyxBalloon"
          label="既往弹簧圈/Onyx/球囊植入情况"
        ></el-table-column>
        <el-table-column
          prop="coilOnyxBalloonType"
          label="既往弹簧圈/Onyx/球囊类型"
        ></el-table-column>
        <el-table-column prop="coilCount" label="既往弹簧圈植入数量"></el-table-column>
        <el-table-column prop="onyxCount" label="既往Onyx胶植入数量"></el-table-column>
        <el-table-column prop="balloonCount" label="既往球囊数量"></el-table-column>
        <el-table-column prop="handlingOpinion" label="本次处理意见"></el-table-column>
      </el-table>
      <nerve-ccf-info-dialog
        ref="nerveCcfInfoDialog"
        :nerveCcfId="nerveCcfId"
        :isReadonly="isReadonly"
        @save="_dialogSave"
      ></nerve-ccf-info-dialog>
    </div>
    <div v-show="selectedInfo?.handlingOpinion?.includes('本次治疗')" class="surgery-information">
      <el-tabs v-model="tabs_index" @tab-click="handleClick" type="border-card">
        <!-- Onyx胶治疗 -->
        <el-tab-pane
          :key="key_list[0]"
          v-if="
            selectedInfo &&
            selectedInfo.handlingOpinion &&
            handlingOpinionList.includes('本次治疗') &&
            handlingOpinionList.includes('Onyx胶治疗')
          "
        >
          <span slot="label">
            <i :class="NerveCcfOnyxTreatDetail.id != null ? 'icon-finished' : 'icon-warn'"></i>
            Onyx胶治疗
          </span>
          <div class="infoEntry-table-tab">
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">该部位介入是否成功</div>
                <cqt-radio
                  style="margin-left: 15px"
                  :value.sync="NerveCcfOnyxTreatDetail.isSucceed"
                  :isReadonly="isReadonly"
                >
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </cqt-radio>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">治疗效果</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/CCF_onyx_treat_treatment_effect"
                  :value.sync="NerveCcfOnyxTreatDetail.treatmentEffect"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">该部位Onyx胶用量</div>
                <div style="padding-left: 15px">
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    size="mini"
                    unit="ml"
                    v-model="NerveCcfOnyxTreatDetail.onyxDosage"
                  />
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">术后并发症</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/CCF_onyx_treat_postoperative_complication"
                  :value.sync="NerveCcfOnyxTreatDetail.postOperativeComplication"
                />
              </div>
            </div>
          </div>
        </el-tab-pane>
        <!-- 弹簧圈栓塞 -->
        <el-tab-pane
          :key="key_list[1]"
          v-if="
            selectedInfo &&
            selectedInfo.handlingOpinion &&
            handlingOpinionList.includes('本次治疗') &&
            handlingOpinionList.includes('弹簧圈栓塞')
          "
        >
          <span slot="label">
            <i
              :class="nerveCcfCoilEmbolizationDetail.id != null ? 'icon-finished' : 'icon-warn'"
            ></i>
            弹簧圈栓塞
          </span>
          <div class="infoEntry-table-tab">
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">该部位介入是否成功</div>
                <cqt-radio
                  style="margin-left: 15px"
                  :value.sync="nerveCcfCoilEmbolizationDetail.isSucceed"
                  :isReadonly="isReadonly"
                >
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </cqt-radio>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">治疗效果</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/CCF_coil_embolization_treatment_effect"
                  :value.sync="nerveCcfCoilEmbolizationDetail.treatmentEffect"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">该部位植入弹簧圈数量</div>
                <div style="padding-left: 15px">
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    size="mini"
                    unit="枚"
                    v-model="nerveCcfCoilEmbolizationDetail.coilCount"
                  />
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">术后并发症</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/CCF_coil_embolization_postoperative_complication"
                  :value.sync="nerveCcfCoilEmbolizationDetail.postOperativeComplication"
                />
              </div>
            </div>
          </div>
        </el-tab-pane>
        <!-- Onyx胶&弹簧圈栓塞 -->
        <el-tab-pane
          :key="key_list[2]"
          v-if="
            selectedInfo &&
            selectedInfo.handlingOpinion &&
            handlingOpinionList.includes('本次治疗') &&
            handlingOpinionList.includes('Onyx胶&弹簧圈栓塞')
          "
        >
          <span slot="label">
            <i :class="nerveCcfOnyxCoilDetail.id != null ? 'icon-finished' : 'icon-warn'"></i>
            Onyx胶&弹簧圈栓塞
          </span>
          <div class="infoEntry-table-tab">
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">该部位介入是否成功</div>
                <cqt-radio
                  style="margin-left: 15px"
                  :value.sync="nerveCcfOnyxCoilDetail.isSucceed"
                  :isReadonly="isReadonly"
                >
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </cqt-radio>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">治疗效果</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/CCF_onyx_coil_treatment_effect"
                  :value.sync="nerveCcfOnyxCoilDetail.treatmentEffect"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">该部位植入弹簧圈数量</div>
                <div style="padding-left: 15px">
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    size="mini"
                    unit="枚"
                    v-model="nerveCcfOnyxCoilDetail.coilCount"
                  />
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">该部位Onyx胶用量</div>
                <div style="padding-left: 15px">
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    size="mini"
                    unit="ml"
                    v-model="nerveCcfOnyxCoilDetail.onyxDosage"
                  />
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">术后并发症</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/CCF_onyx_coil_postoperative_complication"
                  :value.sync="nerveCcfOnyxCoilDetail.postOperativeComplication"
                />
              </div>
            </div>
          </div>
        </el-tab-pane>
        <!-- 可解脱球囊治疗 -->
        <el-tab-pane
          :key="key_list[3]"
          v-if="
            selectedInfo &&
            selectedInfo.handlingOpinion &&
            handlingOpinionList.includes('本次治疗') &&
            handlingOpinionList.includes('可解脱球囊治疗')
          "
        >
          <span slot="label">
            <i :class="nerveCcfBalloonTherapyDetail.id != null ? 'icon-finished' : 'icon-warn'"></i>
            可解脱球囊治疗
          </span>
          <div class="infoEntry-table-tab">
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">该部位介入是否成功</div>
                <cqt-radio
                  style="margin-left: 15px"
                  :value.sync="nerveCcfBalloonTherapyDetail.isSucceed"
                  :isReadonly="isReadonly"
                >
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </cqt-radio>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">治疗效果</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/CCF_balloon_therapy_treatment_effect"
                  :value.sync="nerveCcfBalloonTherapyDetail.treatmentEffect"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">该部位植入球囊数量</div>
                <div style="padding-left: 15px">
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    size="mini"
                    unit="枚"
                    v-model="nerveCcfBalloonTherapyDetail.balloonCount"
                  />
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">术后并发症</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/CCF_balloon_therapy_postoperative_complication"
                  :value.sync="nerveCcfBalloonTherapyDetail.postOperativeComplication"
                />
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import NerveCcfInfoDialog from './NerveCcfInfoDialog.vue'

// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../../components/cqt/CqtRadio.vue'
export default {
  name: 'NerveCcfInfoEntry',
  components: {
    NerveCcfInfoDialog,

    CqtSelect,
    CqtInput,
    CqtRadio
  },
  model: {
    prop: 'nerveCcfId',
    event: 'change'
  },
  props: {
    nerveCcfId: {
      type: String,
      require: true
    },
    signed_status: {
      type: Boolean,
      require: true
    },
    isReadonly: {
      type: Boolean,
      default: () => false
    },
    formId: {
      type: String,
      require: true
    },
    allDetail: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    selectedInfo: {
      handler(val) {
        if (val) {
          this.handlingOpinionList = val.handlingOpinion?.split('+') || []
          this.getAllSuggestions(val)
        }
      },
      immediate: true
    },
    allDetail: {
      handler(val) {
        if (val) {
          this._initInfoValue()
        }
      }
    }
  },
  data() {
    return {
      nerveCcfTableData: [],
      selectedInfo: null,
      handlingOpinionList: [],
      tableKey: null,
      key_list: [1, 2, 3, 4],
      tabs_index: 0,
      NerveCcfOnyxTreatDetail: {
        // ccfInformationId: null, //CCF动静脉信息id
        isSucceed: null, //该部位介入是否成功
        treatmentEffect: null, //治疗效果
        onyxDosage: null, //该部位Onyx胶用量
        postOperativeComplication: null //术后并发症
      },
      nerveCcfCoilEmbolizationDetail: {
        // ccfInformationId: null, //CCF动静脉信息id
        isSucceed: null, //该部位介入是否成功
        treatmentEffect: null, //治疗效果
        coilCount: null, //该部位植入弹簧圈数量
        postOperativeComplication: null //术后并发症
      },
      nerveCcfOnyxCoilDetail: {
        // ccfInformationId: null, //CCF动静脉信息id
        isSucceed: null, //该部位介入是否成功
        treatmentEffect: null, //治疗效果
        onyxDosage: null, //该部位Onyx胶用量
        postOperativeComplication: null, //术后并发症
        coilCount: null //该部位植入弹簧圈数量
      },
      nerveCcfBalloonTherapyDetail: {
        // ccfInformationId: null, //CCF动静脉信息id
        isSucceed: null, //该部位介入是否成功
        treatmentEffect: null, //治疗效果
        balloonCount: null, //该部位植入球囊数量
        postOperativeComplication: null //术后并发症
      }
    }
  },
  methods: {
    // 页码切换
    handleClick(tab) {
      this.tabs_index = tab.index
    },
    async _initInfoValue() {
      if (this.nerveAneurysmId == '-1') return
      let url = `/v1/webconsole/eform/extendfields/${this.formId}/${this.allDetail.nerveCcfInformation.id}`
      let formData = new FormData()
      formData.append('elementName', 'nerveCcfInformation')
      this.$api.post(url, formData).then(
        ({ data: res }) => {
          if (res && res.data) {
            // console.log('nerveCcfTableData', res.data)
            const arr = res.data?.map((item) => ({ ...item, id: item.uuid }))
            this.nerveCcfTableData = arr

            for (let i = 0; i < this.nerveCcfTableData.length; i++) {
              // 耗材
              const element = this.nerveCcfTableData[i]
              // 处理意见handlingSuggestions表示有二级分类
              if (element.handlingOpinion) {
                this.getAllSuggestions(element, 'init')
              }
            }
          }
        },
        () => {}
      )
    },

    getAllSuggestions(val, type) {
      if (!val.handlingOpinion?.includes('本次治疗')) return
      // Onyx胶治疗
      if (val.handlingOpinion?.includes('Onyx胶治疗')) {
        this._getNerveCcfOnyxTreatInfo(val, type)
      }
      // 弹簧圈栓塞
      if (val.handlingOpinion?.includes('弹簧圈栓塞')) {
        this._getNerveCcfCoilEmbolizationInfo(val, type)
      }
      // Onyx胶&弹簧圈栓塞
      if (val.handlingOpinion?.includes('Onyx胶&弹簧圈栓塞')) {
        this._getNerveCcfOnyxCoilInfo(val, type)
      }
      // 可解脱球囊治疗
      if (val.handlingOpinion?.includes('可解脱球囊治疗')) {
        this._getNerveCcfBalloonTherapyInfo(val, type)
      }
    },

    nerveCcfInsert() {
      // if (this.nerveCcfId == '-1' || !this.nerveCcfId)
      //   return this.$message.warning('请先完善动静脉基本信息')
      this.$refs.nerveCcfInfoDialog.Show()
    },
    nerveCcfModify() {
      if (!this.selectedInfo) return this.$message.warning('请先选择一条动静脉信息')
      if (this.selectedInfo) {
        this.$refs.nerveCcfInfoDialog.Show(this.selectedInfo)
      }
    },
    nerveCcfDelete() {
      if (!this.selectedInfo) return this.$message.warning('请先选择一条动静脉信息')
      this.$confirm('是否删除该动静脉信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (this.selectedInfo.id) {
            const index = this.nerveCcfTableData.findIndex(
              (item) => item.id === this.selectedInfo.id
            )
            if (index !== -1) {
              this.nerveCcfTableData.splice(index, 1)
            }
          } else {
            const index = this.nerveCcfTableData.findIndex(
              (item) => item.ids === this.selectedInfo.ids
            )
            if (index !== -1) {
              this.nerveCcfTableData.splice(index, 1)
            }
          }
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.selectedInfo = null
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    _rowClick(row) {
      this.selectedInfo = null
      this.selectedInfo = row

      if (row.id) {
        this.key_list = [
          row.id,
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000)
        ]
      } else {
        this.key_list = [
          row.ids,
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000)
        ]
      }
    },
    _doubleModify(row) {
      this.selectedInfo = null
      this.selectedInfo = row
      this.$refs.nerveCcfInfoDialog.Show(this.selectedInfo)
    },
    _dialogSave(val) {
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.nerveCcfTableData.length; i++) {
          const element = this.nerveCcfTableData[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.nerveCcfTableData[i] = val
            this.$set(this.nerveCcfTableData, i, this.nerveCcfTableData[i])
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.nerveCcfTableData.push(val)
        }
      } else {
        for (let i = 0; i < this.nerveCcfTableData.length; i++) {
          const element = this.nerveCcfTableData[i]
          if (val.id === element.id) {
            this.nerveCcfTableData[i] = val
            this.$set(this.nerveCcfTableData, i, this.nerveCcfTableData[i])
            break
          }
        }
      }
    },

    // Onyx胶治疗
    async _getNerveCcfOnyxTreatInfo(val, type) {
      if (type === 'init') {
        try {
          const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.id}`
          let formData = new FormData()
          formData.append('elementName', 'nerveCcfOnyxTreat')
          const res = await this.$api.post(url, formData)
          if (res.data && res.data.data && res.data.data.length) {
            val.nerveCcfOnyxTreat = { ...res.data.data[0], id: res.data.data[0].uuid }
            this.NerveCcfOnyxTreatDetail = val.nerveCcfOnyxTreat
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        this.NerveCcfOnyxTreatDetail = val.nerveCcfOnyxTreat || {
          ...this.$options.data().NerveCcfOnyxTreatDetail
        }

        val.nerveCcfOnyxTreat = this.NerveCcfOnyxTreatDetail
      }
    },
    _clearNerveCcfOnyxTreatInfo() {
      this.NerveCcfOnyxTreatDetail = {
        ccfInformationId: null, //CCF动静脉信息id
        isSucceed: null, //该部位介入是否成功
        treatmentEffect: null, //治疗效果
        onyxDosage: null, //该部位Onyx胶用量
        postOperativeComplication: null //术后并发症
      }
    },

    // 弹簧圈栓塞
    async _getNerveCcfCoilEmbolizationInfo(val, type) {
      if (type === 'init') {
        try {
          const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.id}`
          let formData = new FormData()
          formData.append('elementName', 'nerveCcfCoilEmbolization')
          const res = await this.$api.post(url, formData)
          if (res.data && res.data.data && res.data.data.length) {
            val.nerveCcfCoilEmbolization = { ...res.data.data[0], id: res.data.data[0].uuid }
            this.nerveCcfCoilEmbolizationDetail = val.nerveCcfCoilEmbolization
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        this.nerveCcfCoilEmbolizationDetail = val.nerveCcfCoilEmbolization || {
          ...this.$options.data().nerveCcfCoilEmbolizationDetail
        }

        val.nerveCcfCoilEmbolization = this.nerveCcfCoilEmbolizationDetail
      }
    },
    _clearNerveCcfCoilEmbolizationInfo() {
      this.nerveCcfCoilEmbolizationDetail = {
        ccfInformationId: null, //CCF动静脉信息id
        isSucceed: null, //该部位介入是否成功
        treatmentEffect: null, //治疗效果
        coilCount: null, //该部位植入弹簧圈数量
        postOperativeComplication: null //术后并发症
      }
    },

    // Onyx胶&弹簧圈栓塞
    async _getNerveCcfOnyxCoilInfo(val, type) {
      if (type === 'init') {
        try {
          const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.id}`
          let formData = new FormData()
          formData.append('elementName', 'nerveCcfOnyxCoil')
          const res = await this.$api.post(url, formData)
          if (res.data && res.data.data && res.data.data.length) {
            val.nerveCcfOnyxCoil = { ...res.data.data[0], id: res.data.data[0].uuid }
            this.nerveCcfOnyxCoilDetail = val.nerveCcfOnyxCoil
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        this.nerveCcfOnyxCoilDetail = val.nerveCcfOnyxCoil || {
          ...this.$options.data().nerveCcfOnyxCoilDetail
        }
        val.nerveCcfOnyxCoil = this.nerveCcfOnyxCoilDetail
      }
    },
    _clearNerveCcfOnyxCoilInfo() {
      this.nerveCcfOnyxCoilDetail = {
        ccfInformationId: null, //CCF动静脉信息id
        isSucceed: null, //该部位介入是否成功
        treatmentEffect: null, //治疗效果
        onyxDosage: null, //该部位Onyx胶用量
        postOperativeComplication: null, //术后并发症
        coilCount: null //该部位植入弹簧圈数量
      }
    },

    // 可解脱球囊治疗
    async _getNerveCcfBalloonTherapyInfo(val, type) {
      if (type === 'init') {
        try {
          const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.id}`
          let formData = new FormData()
          formData.append('elementName', 'nerveCcfBalloonTherapy')
          const res = await this.$api.post(url, formData)
          if (res.data && res.data.data && res.data.data.length) {
            val.nerveCcfBalloonTherapy = { ...res.data.data[0], id: res.data.data[0].uuid }
            this.nerveCcfBalloonTherapyDetail = val.nerveCcfBalloonTherapy
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        this.nerveCcfBalloonTherapyDetail = val.nerveCcfBalloonTherapy || {
          ...this.$options.data().nerveCcfBalloonTherapyDetail
        }
        val.nerveCcfBalloonTherapy = this.nerveCcfBalloonTherapyDetail
      }
    },
    _clearNerveCcfBalloonTherapyInfo() {
      this.nerveCcfBalloonTherapyDetail = {
        ccfInformationId: null, //CCF动静脉信息id
        isSucceed: null, //该部位介入是否成功
        treatmentEffect: null, //治疗效果
        balloonCount: null, //该部位植入球囊数量
        postOperativeComplication: null //术后并发症
      }
    }
  }
}
</script>

<style scoped lang="less">
.infoEntry-table-tab {
  // border-style: solid;
  // border-width: medium;
  // border-color: #ebeef5;
  display: flex;
  width: 99%;
  padding: 15px 0;
}
.info-table {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 20px 15px;
  border-radius: 5px;
  position: relative;
  background-color: #fff !important;
  box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
  /deep/.el-table .cell {
    white-space: normal;
  }
  /deep/.top-item-row .item-row span {
    margin: 0 0 0 5px;
  }
  button {
    margin-right: 0.5rem;
  }
}
.inner-title {
  position: absolute;
  top: -12px;
  left: 12px;
  background: #fff;
  padding: 0 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
}
.inner-table {
  margin-top: 10px;
  border: 1px solid black;
  height: 200px;
  position: relative;
}
.inner-tabs {
  margin: 10px 10px;
  height: 250px;
}
.item-inline {
  margin: 15px 25px;
  display: flex;
  justify-content: flex-end;
}
.item-inline > span {
  height: 25px;
  line-height: 25px;
  margin-right: 10px;
}
.item-column {
  display: flex;
  flex-direction: column;
}
.button-panel {
  width: 100%;
  bottom: 0;
  margin: 20px 0 20px 0;
  display: flex;
  justify-content: space-around;
}
.icon-finished::before {
  content: url('../../../../assets/img/finished.png');
}
.icon-warn::before {
  content: url('../../../../assets/img/warn.png');
}

.surgery-information {
  margin-top: 30px;
  margin-bottom: 20px;

  .input-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .input-form-item-row {
    width: 100%;
    margin: 0 0.5rem;
    display: flex;
    justify-content: flex-start;
  }

  .input-form-item {
    min-width: 30%;
    margin: 0.4rem 1.5rem;
    display: flex;
    align-items: center;
    .input_has_unit {
      margin: 0;
    }
  }
  @media screen and (max-width: 1180px) {
    .input-form-item {
      min-width: 45%;
      margin: 0.4rem 1rem;
    }
  }

  .input-form-item-label {
    width: 140px;
  }
}
</style>
